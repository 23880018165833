<template>
    <div class="cloud-container">
        <div class="cloud"></div>
        <div class="cloud"></div>
        <div class="cloud"></div>
        <div class="cloud"></div>
    </div>
</template>

<style scoped lang="scss">
@keyframes loop1 {
    0% {
        top:20px;
        left:10%;
    }
    80% {
        top:20px;
        left:110%;
    }
    80.1% {
        top:-10000px;
    }
    80.2% {
        left:-20%;
        top:20px;
    }
    100% {
        top:20px;
        left:10%
    }
}
@keyframes loop2 {
    0% {
        left:70%;
        top:-20px;
    }
    25% {
        left:110%;
        top:-20px;
    }
    25.1% {
        top:-10000px;
    }
    25.2% {
        top:-20px;
        left:-20%;
    }
    100% {
        top:-20px;
        left:70%
    }
}
@keyframes loop3 {
    0% {
        top:50px;
        left:40%;
    }
    60% {
        top:50px;
        left:100%;
    }
    60.1% {
        top:-10000px;
    }
    60.2% {
        top:50px;
        left:-20%;
    }
    100% {
        top:50px;
        left:40%
    }
}
@keyframes loop4 {
    0% {
        left:90%;
        top:70px;
    }
    5% {
        left:100%;
        top:70px;
    }
    5.1% {
        top:-10000px;
    }
    5.2% {
        top:70px;
        left:-20%;
    }
    100% {
        top:70px;
        left:90%
    }
}
.cloud-container {
    position:absolute;
    width:100%;
    height:250px;
    .cloud {
        background:url('~@/assets/cloud.png') no-repeat center center;
        background-size:contain;
        width:167px;
        height:180px;
        content:'';
        position:absolute;
        &:nth-child(1) {
            animation:loop1 200s linear;
            animation-fill-mode:both;
            animation-iteration-count: infinite;
            opacity:0.7;
        }
        &:nth-child(2) {
            animation:loop2 200s linear;
            animation-fill-mode:both;
            animation-iteration-count: infinite;
            width:150px;
            height:162px;
            opacity:0.8;
        }
        &:nth-child(3) {
            animation:loop3 200s linear;
            animation-fill-mode:both;
            animation-iteration-count: infinite;
            width:116px;
            height:126px;
            opacity:0.5;
        }
        &:nth-child(4) {
            animation:loop4 200s linear;
            animation-fill-mode:both;
            animation-iteration-count: infinite;
            width:100px;
            height:108px;
            opacity:0.3;
        }
    }
}
</style>